import Joi from "joi";
import errorMessages from "./errorMessages.json";
import nameRegex from "./custom/nameRegex";

export default Joi.object({
  first_name: Joi.string()
    .max(50)
    .pattern(nameRegex.name)
    .required(),
  last_name: Joi.string()
    .max(50)
    .pattern(nameRegex.name)
    .required(),
  middle_name: Joi.string()
    .allow(null, "")
    .max(50)
    .pattern(nameRegex.name),
  role: Joi.string().required(),
  bio: Joi.string()
    .allow(null, "")
    .max(500)
})
  .unknown()
  .messages(errorMessages);
