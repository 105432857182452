<template>
  <div class="contributors">
    <b-row>
      <b-col>
        <h5 class="mb-4">Authors & Contributors</h5>
        <ebp-button @click="openAdd">{{ $t("add-a-contributor") }}</ebp-button>
      </b-col>
      <!-- <b-col class="text-right">
        <ebp-button @click="openAdd">{{ $t("add-a-contributor") }}</ebp-button>
      </b-col> -->
    </b-row>

    <data-table
      :items="book.authors"
      :headers="headers"
      :pagination="false"
      v-if="book.authors.length"
      :no-items-text="$t('no-contributors')"
    >
      <template #cell(name)="{ item }">
        {{ item.first_name }} {{ item.last_name }}
      </template>
      <template #actions="{ index, item }">
        <ebp-button
          @click="openEdit(item, index)"
          size="sm"
          class="py-0 px-1 ml-1"
        >
          <i class="mdi mdi-pencil"></i
        ></ebp-button>
        <ebp-button
          :loading="loadingRemove === item.id"
          :disabled="loadingRemove"
          @click="remove(index, item)"
          variant="danger"
          size="sm"
          class="py-0 px-1 ml-1"
        >
          <i class="mdi mdi-delete"></i
        ></ebp-button>
      </template>
    </data-table>

    <p class="text-center py-5" v-else>
      {{ $t("no-contributors") }}
    </p>

    <EbpModal
      :open.sync="modalOpen"
      :tabs="[`${adding ? 'add' : 'edit'}-contributor`]"
    >
      <template #edit-contributor>
        <error :err.sync="error" />
        <FormGenerator
          :data="{ ...editedItem, book_type: book.book_type }"
          :elements="elements"
          :handleUpdate="
            ({ key }, value) => editedItem && (editedItem[key] = value)
          "
        />
      </template>
      <template #add-contributor>
        <error :err.sync="error" />
        <FormGenerator
          :data="{ ...editedItem, book_type: book.book_type }"
          :elements="elements"
          :handleUpdate="
            ({ key }, value) => editedItem && (editedItem[key] = value)
          "
        />
      </template>
    </EbpModal>
  </div>
</template>
<script>
import FormGenerator from "@/components/form-generator";
import { mapState } from "vuex";
import contributorRoles from "@/fixtures/contributor-roles";
import contributorFields from "@/fixtures/contributor-fields";
import DataTable from "@/components/data-table";
import { cloneDeep } from "lodash-es";
import authorSchema from "@/validation-schemas/author";
import EbpModal from "../ebp-modal.vue";

export default {
  name: "contributors",
  components: {
    FormGenerator,
    DataTable,
    EbpModal
  },
  props: {
    book: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loadingRemove: false,
      loading: false,
      author: {
        first_name: null,
        middle_name: null,
        last_name: null,
        role: null,
        bio: null
      },
      roleOptions: [{ text: this.$t("select-role"), value: null }].concat(
        contributorRoles.map(role => ({
          text: this.$t(role),
          value: role
        }))
      ),
      error: null,
      modalOpen: false,
      editedItem: null,
      editedIdx: null,
      adding: false
    };
  },
  computed: {
    ...mapState("ancillaries", ["languages"]),
    headers() {
      const headers = [
        {
          key: "first_name",
          label: this.$t("first-name")
        },
        {
          key: "middle_name",
          label: this.$t("middle-name")
        },
        {
          key: "last_name",
          label: this.$t("last-name")
        },
        {
          key: "role",
          select: true,
          attrs: { options: this.roleOptions }
        }
      ];

      if (this.book.book_type === "ebook") {
        headers.push({
          key: "bio",
          textarea: true,
          attrs: { rows: 3 }
        });
      }

      return headers;
    },
    elements() {
      return [
        {
          key: "first_name",
          label: "first-name",
          type: "string"
        },
        {
          key: "middle_name",
          label: "middle-name",
          type: "string"
        },
        {
          key: "last_name",
          label: "last-name",
          type: "string"
        },
        {
          key: "role",
          label: "role",
          type: "select",
          attrs: {
            options: this.roleOptions
          }
        },
        {
          key: "bio",
          label: "bio",
          type: "text",
          className: "mb-4",
          showIf: [["book_type", "ebook"]]
        },
        {
          type: "button",
          text: "save-contributor",
          className: "mt-8 px-4 float-right mt-sm-0",
          attrs: {
            loading: this.loading
          },
          mobileAttrs: {
            block: true
          },
          events: {
            click: () => this.save(!this.adding)
          }
        }
      ];
    }
  },
  methods: {
    openAdd() {
      this.adding = true;
      this.editedItem = cloneDeep(contributorFields);
      this.modalOpen = true;
    },
    openEdit(item, idx) {
      this.editedItem = item;
      this.editedIdx = idx;
      this.modalOpen = true;
      this.adding = false;
    },
    handleUpdate(el, value) {
      if (el.key) this.author[el.key] = value;
    },
    save(update = false) {
      this.error = null;
      const author = this.editedItem;
      const index = this.editedIdx;

      const validation = authorSchema.validate(author, this.joiOptions);

      const error =
        this.firstContributorMustBe("author", author.role, this.book.authors) ||
        this.totalContributorsShouldNotExceedFour(this.book.authors);

      if (validation.error || error) {
        this.error = error || validation.error.message;
      } else {
        const book = cloneDeep(this.$props.book);
        if (update) {
          book.authors = book.authors.map((a, i) => {
            return i === index ? author : a;
          });
        } else {
          book.authors.push(author);
        }

        this.$emit("update:book", book);

        this.editedItem = cloneDeep(contributorFields);
        this.resetModal();
      }
    },
    firstContributorMustBe(role, selectedRole, contributors) {
      return (
        (contributors.length ? contributors[0].role : selectedRole) !== role &&
        this.$t("validation-errors.first-contributor-author")
      );
    },
    totalContributorsShouldNotExceedFour(contributors) {
      return (
        contributors.length >= 4 &&
        "Sorry! A book can have a maximum of 4 contributors."
      );
    },
    resetModal() {
      // Reset edit
      this.editedItem = null;
      this.editedIdx = null;
      this.modalOpen = null;
    },
    remove(idx) {
      const book = this.$props.book;

      book.authors = this.$props.book.authors.filter((_, i) => idx !== i);
      this.$emit("update:book", book);
    }
  }
};
</script>

<style></style>
